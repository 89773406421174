import React from 'react'
import { connect } from "react-redux"
import { Alert } from 'reactstrap'
import { HideToast } from "../global/Utils"
import ReactDOM from 'react-dom'

const Alerts = (props) => {

    if (!props.attr.show)
        return (<></>)

    if (!props.attr.fixed)
        setTimeout(function () {
            props.dispatch(HideToast())
        }, 2000)
    const alertContent = (
        <div style={{
            position: "fixed",
            top: "5px",
            left: "0",
            width: "100%",
            zIndex: 2000,  // High z-index to layer on top of everything
            display: "block"
        }}>
            <Alert
                color={props.attr.color}
                fade={true}
                style={{
                    maxWidth: "30%",
                    margin: "0 auto",
                    paddingTop: "20px",
                    paddingBottom: "20px"
                }}
                toggle={props.attr.fixed ? () => props.dispatch(HideToast()) : undefined}
            >
                {props.attr.text}
            </Alert>
        </div>
    );

    // Render the alert content directly in the document body
    return ReactDOM.createPortal(alertContent, document.body);

}

export default connect()(Alerts)