import React, { Component } from "react"
import { connect } from "react-redux"
import { GetParameter, Toast, ToastTypes, Loading } from "../components/global/Utils"
import { faEdit, faPlus, faSave, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import McdTable from "../components/global/Table"
import AdminNavbar from './AdminNavbar';
import { Button, Modal, ModalFooter, ModalHeader, ModalBody, Row, Col, Input, Label  } from 'reactstrap'
class Profiles extends Component {
    constructor(props) {
        super(props)

        this.state = {
            table: {
                title: "",
                columns: { title: [], width: [], align: [], search: [] },
                rows: [],
                pagination: true,
                actions: []
            },
            profiles: [],
            Environment: sessionStorage.getItem("environment") || 1,
            modal: { isOpen: false, isNew: true, profileId: "", profileName: "" },
            modalCopyEnv: { isOpen: false, from: null, to:null}
        }

        this.handleSaveProfile = this.handleSaveProfile.bind(this)
        this.handleDeleteProfile = this.handleDeleteProfile.bind(this)
        this.handleChangeEnvironment = this.handleChangeEnvironment.bind(this)
        this.handleCopyProfiles = this.handleCopyProfiles.bind(this)
        this.handlePasteProfiles = this.handlePasteProfiles.bind(this)
    }

    componentDidMount() {
        this.getProfiles()
    }

    getProfiles(env) {
        let appId = GetParameter("id")
        console.log("AAAAAAAAAAAAAAAAAAAA" + sessionStorage.getItem("access_token"));
        const storedToken = sessionStorage.getItem("access_token");
        this.props.dispatch({ type: "ACCESS_TOKEN", payload: storedToken })
        console.log("CCCCCCCCCCCCCCCCCCC" + this.props.access_token);
        if (appId == null || this.props.access_token == null) {
            this.props.history.replace("/h1f2d1i1d2b")
            return;
        }

        const requestOptions = {
            method: "GET",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" }
        }

        this.props.dispatch(Loading(true))

        if (env == null)
            env = this.state.Environment

        fetch("/h1f2d1i1d2b/admin/profiles/" + appId + "/" + env, requestOptions)
            .then(response => { if (response.status === 200) return response.json(); return response.then(Promise.reject.bind(Promise)) })
            .then(json => {
                this.setState({
                    profiles: json.profiles,
                    table: {
                        title: json.appName + " - Perfis",
                        columns: {
                            title: ["Id", "Nome", ""],
                            width: [5, 90, 5],
                            align: ["left", "left", "right"],
                            search: [true, true, false]
                        },
                        rows: this.buildGridRows(json.profiles),
                        pagination: true,
                        actions: [
                            { action: (e) => this.handleNewProfile(e), icon: faPlus, title: "Novo perfil" }
                        ]
                    }
                })

                this.props.dispatch(Loading(false))
            })
            .catch((e) => { this.props.dispatch(Toast("Não foi possível obter os registos", ToastTypes.Danger, false)); console.error(e); })
    }

    buildGridRows(json) {
        return json.map(row => {
            return {
                id: row.profileId,
                columns: [
                    { column: row.profileId },
                    { column: row.profileName }
                ],
                actions: [
                    { column: faEdit, action: (e) => this.handleEditProfile(e, row.profileId), tooltip: "Editar" }
                ]
            }
        })
    }

    toggle = () => {
        this.setState(prevState => { return { modal: { ...prevState.modal, isOpen: !prevState.modal.isOpen } } })
    }

    toggleModalCopyPaste = () => {
        this.setState(prevState => { return { modalCopyEnv: { ...prevState.modalCopyEnv, isOpen: !prevState.modalCopyEnv.isOpen } } })
    }

    handleChangeEnvironment(e) {

        this.setState({ Environment: e })

        this.getProfiles(e)
    }

    handleNewProfile(e) {
        e.preventDefault();
        this.setState({ modal: { isOpen: true, isNew: true, profileId: "", profileName: "" } })
    }

    handleEditProfile(e, profileId) {
        e.preventDefault();
        const profile = this.state.profiles.find(f => f.profileId === profileId)

        this.setState({ modal: { isOpen: true, isNew: false, profileId: profile.profileId, profileName: profile.profileName } })
    }

    handleCopyProfiles() {
        this.setState({ modalCopyEnv: { isOpen: true, from: this.state.Environment, to: (this.state.Environment === 1 ? 2 : 1) } })
    }


    handlePasteProfiles() {

        const requestOptions = {
            method: "POST",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" }
        }

        this.props.dispatch(Loading(true))
        
        fetch("/h1f2d1i1d2b/admin/profiles/" + GetParameter("id") + "/" + String(this.state.modalCopyEnv.from) + "/" + String(this.state.modalCopyEnv.to), requestOptions)
            .then(response => {
                if (response.status !== 200) {
                    this.props.dispatch(Toast("Não foi possível gravar!", ToastTypes.Danger, true))
                    return
                }

                this.setState({
                    modalCopyEnv: {
                        ...this.state.modalCopyEnv,
                        isOpen: false, from:null, to:null
                    }
                })

                this.props.dispatch(Toast("Perfis copiados", ToastTypes.Success, false))
            })
            .catch((e) => { this.props.dispatch(Toast("Não foi possível copiar!", ToastTypes.Danger, true)); console.error(e) })
    }

    handleSaveProfile() {

        if (this.state.modal.profileId === "" || this.state.modal.profileName === "") {
            this.props.dispatch(Toast("Necessário preencher os campos", ToastTypes.Warning, false))
            return
        }

        if (this.state.modal.isNew && this.state.profiles.find(f => f.profileId === parseInt(this.state.modal.profileId)) !== undefined) {
            this.props.dispatch(Toast("Id de perfil já existente", ToastTypes.Warning, false))
            return
        }

        const data = {
            profileId: parseInt(this.state.modal.profileId),
            profileName: this.state.modal.profileName,
            environment: parseInt(this.state.Environment)
        }

        const requestOptions = {
            method: "PUT",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" },
            body: JSON.stringify(data)
        }

        this.props.dispatch(Loading(true))

        fetch("/h1f2d1i1d2b/admin/profiles/" + GetParameter("id"), requestOptions)
            .then(response => {
                if (response.status !== 200) {
                    this.props.dispatch(Toast("Não foi possível gravar!", ToastTypes.Danger, true))
                    return
                }

                let gRows = [...this.state.table.rows]
                let profiles = this.state.profiles
                let index = profiles.findIndex(f => f.profileId === parseInt(this.state.modal.profileId))

                if (!this.state.modal.isNew) {
                    profiles[index].profileId = data.profileId
                    profiles[index].profileName = data.profileName

                    gRows[index].columns[0].column = data.profileId
                    gRows[index].columns[1].column = data.profileName
                }
                else {
                    profiles.push(data)

                    const newRow = {
                        id: data.profileId,
                        columns: [
                            { column: data.profileId },
                            { column: data.profileName }
                        ],
                        actions: [
                            { column: faEdit, action: (e) => this.handleEditProfile(e, data.profileId), tooltip: "Editar" }
                        ]
                    }

                    gRows = [...gRows, newRow]
                }

                this.setState({
                    modal: {
                        ...this.state.modal,
                        isOpen: false, isNew: true, profileId: "", profileName: ""
                    },
                    profiles: profiles,
                    table: { ...this.state.table, rows: gRows }
                })

                this.props.dispatch(Toast("Perfil gravado", ToastTypes.Success, false))
            })
            .catch((e) => { this.props.dispatch(Toast("Não foi possível gravar!", ToastTypes.Danger, true)); console.error(e) })
    }

    handleDeleteProfile() {
        if (!window.confirm("O perfil \"" + this.state.modal.profileName + "\" será removido. Confirma?"))
            return;

        const requestOptions = {
            method: "DELETE",
            headers: { authorization: "bearer " + this.props.access_token, "Content-Type": "application/json" }
        }

        this.props.dispatch(Loading(true))

        fetch("/h1f2d1i1d2b/admin/profiles/" + GetParameter("id") + "?profile=" + this.state.modal.profileId + "&env=" + this.state.Environment, requestOptions)
            .then(response => {
                if (response.status !== 200) {
                    this.props.dispatch(Toast("Não foi possível remover!", ToastTypes.Danger, true))
                    return
                }

                let gRows = this.state.table.rows.filter(f => f.id !== parseInt(this.state.modal.profileId))
                let profiles = this.state.profiles.filter(f => f.profileId !== parseInt(this.state.modal.profileId))

                this.setState({
                    modal: {
                        ...this.state.modal,
                        isOpen: false, isNew: true, profileId: "", profileName: ""
                    },
                    profiles: profiles,
                    table: { ...this.state.table, rows: gRows }
                })

                this.props.dispatch(Toast("Perfil removido", ToastTypes.Success, false))
            })
            .catch((e) => { this.props.dispatch(Toast("Não foi possível remover!", ToastTypes.Danger, true)); console.error(e) })
    }
    navigateTo = (path) => {
        const appId = GetParameter("id");
        if (appId) {
            this.props.history.push(`/h1f2d1i1d2b/${path}?id=${appId}`);
        } else {
            this.props.history.replace("/h1f2d1i1d2b");
        }
    }
    render() {

        return (
            <div className="right-panel" style={{ marginLeft: 0, marginTop: 0 }}>
                <div className={"content"} style={{ backgroundColor: "white" }}>

                    <AdminNavbar
                        environment={this.state.Environment}
                        onEnvironmentChange={this.handleChangeEnvironment}
                        onCopyProfiles={this.handleCopyProfiles}
                        navTitle={" "}
                        id={GetParameter("id")}
                    />
                    <McdTable title={this.state.table.title} columns={this.state.table.columns} rows={this.state.table.rows} pagination={this.state.table.pagination}
                        actions={this.state.table.actions} filters={this.state.table.filters} handlerFilter={this.handleFilter} urlSearch={this.props.urlSearch} />
                    <Modal isOpen={this.state.modal.isOpen} toggle={this.toggle}>
                        <ModalHeader toggle={this.toggle}>Perfil</ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col style={{ textAlign: "left" }} xs="3">
                                    <Label>Id*</Label>
                                    <Input id="ProfileId" type="number" value={this.state.modal.profileId} min="0"
                                        disabled={!this.state.modal.isNew} onChange={(e) => this.setState({ modal: { ...this.state.modal, profileId: e.target.value } })} />
                                </Col>
                                <Col style={{ textAlign: "left" }}>
                                    <Label>Nome*</Label>
                                    <Input id="ProfileName" type="text" value={this.state.modal.profileName} autoComplete="off" maxLength="100"
                                        onChange={(e) => this.setState({ modal: { ...this.state.modal, profileName: e.target.value } })} />
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter style={{ display: "block" }}>
                            <Button color="info" onClick={this.handleDeleteProfile} style={{ float: "left", display: !this.state.modal.isNew ? "inline-block" : "none" }}>
                                <FontAwesomeIcon icon={faTrash} /> Remover</Button>
                            <Button color="primary" style={{ float: "right" }}
                                onClick={this.handleSaveProfile}><FontAwesomeIcon icon={faSave} /> Gravar</Button>
                        </ModalFooter>
                    </Modal>


                    <Modal isOpen={this.state.modalCopyEnv.isOpen} toggle={this.toggleModalCopyPaste} id="ModalCopyPaste">
                        <ModalHeader toggle={this.toggleModalCopyPaste}>Copiar perfis para...</ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col style={{ textAlign: "left" }} xs="12">
                                    <Input type="select" id="ddlPasteEnvironment" onChange={(e) => this.setState({ modalCopyEnv: { ...this.state.modalCopyEnv, to: e.target.value } })}>
                                        <option value="1" disabled={this.state.modalCopyEnv.from === 1}>Produção</option>
                                        <option value="2" disabled={this.state.modalCopyEnv.from === 2}>Qualidade</option>
                                        <option value="3" disabled={this.state.modalCopyEnv.from === 3}>Desenvolvimento</option>
                                    </Input>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter style={{ display: "block" }}>
                            <Button color="primary" style={{ float: "right" }}
                                onClick={this.handlePasteProfiles}><FontAwesomeIcon icon={faSave}
                                />Copiar</Button>
                        </ModalFooter>
                    </Modal>

                </div>
                </div>
        );
    }
}

const mapStateToProps = state => {
    return { access_token: state.access_token }
}

export default connect(mapStateToProps)(Profiles)