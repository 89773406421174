import React, { Component } from 'react'
import { connect } from "react-redux"
import '../css/Layout.css'
import { Loading } from './global/Loading'
import Alerts from './global/Alerts'

class Layout extends Component {

    render() {
        return (
            <div id="background">
                <Loading show={this.props.loading} />
                <Alerts attr={this.props.alert} />
                <div className="container-fluid" style={{ height: 100 + "%" }}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        alert: state.alert
    }
}

export default connect(mapStateToProps)(Layout)
