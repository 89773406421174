
export const Loading = (show) => {
    return { type: "LOADING", payload: { show } }
}

export const Toast = (text, type, fixed) => {
    if (type === undefined) type = ToastTypes.Info
    if (fixed === undefined) fixed = false

    return { type: "ALERT", payload: { show: true, text: text, color: type, fixed: fixed } }
}

export const ToastTypes = {
    Success: "success",
    Danger: "danger",
    Warning: "warning",
    Info: "info"
}

export const HideToast = () => {
    return { type: "UNMOUNT_ALERT" }
}

export const GetParameter = (parameter) => {
    const urlParams = new URLSearchParams(document.location.search)
    return urlParams.get(parameter)
}

export const APPCONFIG = {}

export function b64EncodeUnicode(str) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
        function toSolidBytes(match, p1) {
            return String.fromCharCode('0x' + p1);
        }));
}

export function b64DecodeUnicode(str) {
        // Going backwards: from bytestream, to percent-encoding, to original string.
        return decodeURIComponent(atob(str).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
}

export function getDateDifferenceDays(date) {
    if (date === null)
        return ""

    var today = new Date();
    var dateString = stringToDate(date.substring(0, 10), "yyyy-MM-dd", "-");

    var oneDay = 24 * 60 * 60 * 1000;
    var diffDays = Math.round(Math.abs((today.getTime() - dateString.getTime()) / (oneDay)));

    return diffDays;
}

export function formatDate(date) {
    if (date === null)
        return ""

    var d = new Date(date.toString()),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
}

export function formatDateWithHours(date) {
    if (date === null)
        return ""

    var d = new Date(date.toString()),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear(),
        hours = d.getHours(),
        minutes = d.getMinutes();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    hours = ("0" + hours).slice(-2);
    minutes = ("0" + minutes).slice(-2);

    return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes;
}

function stringToDate(_date, _format, _delimiter) {
    var formatLowerCase = _format.toLowerCase();
    var formatItems = formatLowerCase.split(_delimiter);
    var dateItems = _date.split(_delimiter);
    var monthIndex = formatItems.indexOf("mm");
    var dayIndex = formatItems.indexOf("dd");
    var yearIndex = formatItems.indexOf("yyyy");
    var month = parseInt(dateItems[monthIndex]);
    month -= 1;
    var formatedDate = new Date(dateItems[yearIndex], month, dateItems[dayIndex]);

    return formatedDate;
}

export function replaceHTML(message, removeLink) {

    let regx = /(http[s]?:\/\/)([^\s]+)/g
    let replace = "<a href=\"$&\" target=\"_blank\">$&</a>"

    if (!regx.test(message) && !removeLink)
        return message

    if (removeLink) {
        regx = /<\/a>/g 
        replace = ""

        message.replaceAll(regx, replace)

        regx = /<a[\s]+([^>]+)>/g
        replace = ""
        message.replaceAll(regx, replace)

        return message
    }

    return message.replaceAll(regx, replace)
}