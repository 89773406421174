import React, { Component } from "react"
import { Route} from "react-router"
import Layout from "./components/Layout"
import { Home } from "./components/Home"
import Applications from "./admin/Applications"
import "./custom.css"
import Profiles from "./admin/Profiles"
import AdminMenu from "./admin/AdminMenu"
import Users from "./admin/Users"
import { connect } from "react-redux"

class App extends Component {

    // Admin: h1f2d1i1d2b
    render() {
        /* Auto Refresh */
        var _this = this;
        if (document.location.href.indexOf("/h1f2d1i1d2b") !== -1)
            setInterval(function () {
                // Get access_token with refresh_token
                fetch("/h1f2d1i1d2b/auth/refresh")
                    .then(response => {
                        if (response.status === 200)
                            return response.json()

                        // don't have refresh token, need authentication
                        alert("O tempo da sessao expirou! Vai ser necessario nova autenticacao para renovar a sessao.")
                        if (response.status === 302)
                            response.text().then(url => document.location.href = url)
                        return null
                    })
                    .then(json => {
                        if (json === null)
                            return

                        _this.props.dispatch({ type: "ACCESS_TOKEN", payload: json })
                    })
                    .catch(e => console.error(e))
            }, 13 * 60 * 1000 /* 13 minutes*/);

        return (
            <Layout>
                <Route exact path="/" component={Home} />
                <Route exact path="/h1f2d1i1d2b" component={Applications} />
                <Route exact path="/h1f2d1i1d2b/callback" component={Applications} />
                <Route exact path="/h1f2d1i1d2b/profiles" component={Profiles} />
                <Route exact path="/h1f2d1i1d2b/menu" component={AdminMenu} />
                <Route exact path="/h1f2d1i1d2b/users" component={Users} />
            </Layout>
        );
    }
}

export default connect()(App)